import {
  login,
  newsletterSignup,
  getCurrent,
  createAddress,
  updateAddress,
  deleteAddress,
  changePassword,
  createAccount,
  updateAccount,
  getCountries,
  addProductToWishlist,
  removeProductFromWishlist,
  getMyOrders,
  createGuestWishlist,
  mergeGuestWishlist,
  addWishlistItemDescription,
  handleShareWishlist,
  getSharedWishlistBySharingCode,
  createAccountFromOrder,
  getReviews
} from "@storefront/core/data-resolver/user";


import {
  getCustomerCartToken,
  mergeCart,
} from "@storefront/core/data-resolver/cart";
import store from "@/store";
import { i18n } from "@storefront/core/i18n";

const state = () => ({
  isLoggedIn: false,
  isApproved: false,
  userToken: null,
  userEmail: null,
  current: {},
  countries: [],
  accountTabIndex: 0,
  editAddressId: 0,
  editUser: false,
  editEmail: false,
  editPassword: false,
  editAddresses: false,
  Fake: null,
  wishlist: { id: null, items: [] },
  myOrders: [],
  totalOrdersCount: 0,
  reviews: [],
});

const actions = {
  /**
   * Load active user
   *
   */
  async loadUser({ getters, commit, dispatch }) {
    if (getters["getIsLoggedIn"] == false) {
      if (getters["getUserToken"] == null) {
        const userToken = localStorage.getItem("userServerToken");

        if (userToken !== null) {
          commit("setUserToken", userToken);
          commit("setIsLoggedIn", true);
          const me = await getCurrent();

          if (me == false) {
            dispatch("logout");
          } else {
            commit("setCurrentUser", me);
            commit("setIsApproved", me.is_approved ?? false);
            dispatch("loadOrders", { page: 1, perPage: 10 });
            dispatch("loadReviews");
            let wl = me.wishlist;

            if (wl == null) {
              wl = { id: null, items: [] };
            }
            commit("setWishlist", wl);
            const newCartToken = await getCustomerCartToken();
            store.commit("cart/setServerToken", newCartToken);
            await store.dispatch("cart/loadCart");
          }
        }
      }
    } else {
      const me = await getCurrent();
      if (me != false) {
        commit("setCurrentUser", me);
        let wl = me.wishlist;
        if (wl == null) {
          wl = { id: null, items: [] };
        }
        commit("setWishlist", wl);
        commit("setIsApproved", me.is_approved ?? false);
        dispatch("loadOrders", { page: 1, perPage: 10 });
        dispatch("loadReviews");
      }
    }
  },
  /**
   * Load user orders
   *
   */
  async loadOrders({ commit }, { page = "1", perPage = "10", search }) {
    const myOrders = await getMyOrders({ page, perPage, search });
    if (myOrders != false) {
      commit("setMyOrdersTotalCount", myOrders.total_count);
      commit("setMyOrders", myOrders.items);
      return myOrders;
    }

  },
  async login({ commit, getters }, { username = "", password = "" }) {
    const retval = await login(username, password);

    if (retval != false) {
      commit("setUserToken", retval);
      commit("setIsLoggedIn", true);

      const me = await getCurrent();
      if (me != false) {
        const newCartToken = await getCustomerCartToken();
        const oldCartToken = store.getters["cart/getCartServerToken"];
        if (oldCartToken != null) {
          await mergeCart(oldCartToken, newCartToken);
        }
        store.commit("cart/setServerToken", newCartToken);
        
        // dispatch("loadUser");

        let wl = me.wishlist;

        if (wl == null) {
          wl = { id: null, items: [] };
        }

        if (getters['getWishlist'].length > 0) {
          await mergeGuestWishlist(getters['getWishlistId'], wl.id);
          wl = { ...wl, items: wl.items.concat(getters['getWishlist'].filter(item => !wl.items.map(item => item.product.sku).includes(item.product.sku))) };
        }

        commit("setWishlist", wl);
        // cart
        await store.dispatch("cart/loadCart");

        // user data
        commit("setCurrentUser", me);
        commit("setIsApproved", me.is_approved ?? false);

      } else {
        commit("setUserToken", null);
        commit("setIsLoggedIn", false);
        commit("setIsApproved", false);
        commit("setCurrentUser", {});
      }
    } else {
      return false;
    }
    return true;
  },
  /**
   * Signup for newsletter
   *
   * @param {object} string email
   * @returns
   */
  async newsletterSignup({ commit, dispatch }, { email = "" }) {
    commit("setFake", null);
    const retval = await newsletterSignup(email);
    if (retval != false) {
      dispatch("loadUser");
      return true;
    }
    return false;
  },
  /**
   * Load countries
   *
   * @returns true
   */
  async loadCountries({ commit }) {
    const retval = await getCountries();
    commit("setCountries", retval);
    return true;
  },
  /**
   * Create user account
   *
   * @param {object} object account
   * @returns
   */
  async createAccount({ commit }, { account }) {
    commit("setFake", null);
    const retval = await createAccount(account);

    if (retval != false) {
      commit("setUserToken", retval);
      commit("setIsLoggedIn", true);
      commit("setIsApproved", false);
      const me = await getCurrent();
      if (me != false) {
        let wl = me.wishlist;
        if (wl == null) {
          wl = { id: null, items: [] };
        }
        commit("setWishlist", wl);
        commit("setCurrentUser", me);
        const newCartToken = await getCustomerCartToken();
        const oldCartToken = store.getters["cart/getCartServerToken"];
        if (oldCartToken != null) {
          await mergeCart(oldCartToken, newCartToken);
        }
        store.commit("cart/setServerToken", newCartToken);
        await store.dispatch("cart/loadCart");
      } else {
        commit("setUserToken", null);
        commit("setIsLoggedIn", false);
        commit("setIsApproved", false);
        commit("setCurrentUser", {});
      }
    } else {
      return false;
    }
    return true;
  },
  /**
   * add product to wishlist
   *
   * @param {object} string sku, string parentSku
   * @returns true or false
   */
  async addProductToWishlist({ dispatch }, { sku, parentSku }) {
    const retval = await addProductToWishlist(sku, parentSku);
    if (retval == true) {
      dispatch("loadUser");
    }
    return retval;
  },
  /**
   * remove product from wishlist
   *
   * @param {object} integer id
   * @returns true or false
   */
  async removeProductFromWishlist({ dispatch }, { id }) {
    const retval = await removeProductFromWishlist(id);
    if (retval == true) {
      dispatch("loadUser");
    }
    return retval;
  },

  /**
   * update account
   *
   * @param {object} object account
   * @returns
   */
  async updateAccount({ commit }, { account }) {
    const retval = await updateAccount(account);
    if (retval == true) {
      const me = await getCurrent();
      if (me != false) {
        commit("setCurrentUser", me);
      }
    }
    return retval;
  },
  /**
   * add address
   *
   * @param {object} object address
   * @returns true or false
   */
  async addAddress({ commit }, { address }) {
    const retval = await createAddress(address);
    if (retval == true) {
      const me = await getCurrent();
      if (me != false) {
        commit("setCurrentUser", me);
      }
    }
    return retval;
  },

  /**
   * updaet address
   *
   * @param {object} object address
   * @returns true or false
   */
  async updateAddress({ commit }, { address }) {
    const retval = await updateAddress(address.id, address);
    if (retval == true) {
      const me = await getCurrent();
      if (me != false) {
        commit("setCurrentUser", me);
      }
    }
    return retval;
  },

  /**
   * update user newsletter
   *
   * @param {object} boolean is_subscribed
   * @returns true or false
   */
  async updateUserNewsletter({ commit }, { is_subscribed }) {
    const account = { is_subscribed: is_subscribed };
    const retval = await updateAccount(account);
    if (retval == true) {
      const me = await getCurrent();
      if (me != false) {
        commit("setCurrentUser", me);
      }
    }
    return retval;
  },

  /**
   * delete address
   *
   * @param {object} integer id
   * @returns
   */
  async deleteAddress({ commit }, { id }) {
    const retval = await deleteAddress(id);
    if (retval == true) {
      const me = await getCurrent();
      if (me != false) {
        commit("setCurrentUser", me);
      }
    }
    return retval;
  },

  /**
   * change password
   *
   * @param {object} string currentPassword, string newPassword
   * @returns
   */
  async changePassword({ commit }, { currentPassword, newPassword }) {
    commit("setFake", null);
    const retval = await changePassword(currentPassword, newPassword);
    return retval;
  },

  /**
   * logout
   *
   */
  logout({ commit }) {
    commit("setUserToken", null);
    commit("setIsLoggedIn", false);
    commit("setIsApproved", false);
    commit("setCurrentUser", {});
    commit("setWishlist", { id: null, items: [] });
    localStorage.removeItem('checkoutPayment');
    store.dispatch("cart/unLoad");
  },
  /**
   * session expired
   *
   */
  sessionExpired({ dispatch }) {
    dispatch("logout");
    const msg = {
      type: "danger",
      title: "session_expired",
      text: i18n.t("session_expired_error"),
    };
    store.dispatch("messages/sendMessage", { message: msg });
  },
  /**
   * set product wishlist status
   *
   * @param {object} string sku, string parentSku
   */
  async setProductWishlistStatus({ getters, dispatch, commit }, { sku, parentSku }) {
    const id = getters["getProductInWishlistId"](sku);
    if (id == null) { // add product to wishlist
      if (getters['getIsLoggedIn']) { // logged in case
        const retval = await addProductToWishlist(sku, parentSku);
        if (retval) {
          dispatch("loadUser");
        }
      } else {// not logged in case
        if (!getters['getWishlistId']) {
          const guestWishlistid = await createGuestWishlist();
          commit('setWishlist', { id: guestWishlistid, items: [] });
        }
        const retval = await addProductToWishlist(sku, parentSku);
        if (retval) {
          commit('setWishlist', retval.data.data.addProductsToWishlist.wishlist);
        }
      }
    } else { // remove product from wishlist
      if (getters['getIsLoggedIn']) {
        const retval = await removeProductFromWishlist(id);
        if (retval) {
          dispatch("loadUser");
        }
      } else {
        if (!getters['getWishlistId']) {
          const guestWishlistid = await createGuestWishlist();
          commit('setWishlist', { id: guestWishlistid, items: [] });
        }
        const retval = await removeProductFromWishlist(id);
        if (retval) {
          commit('setWishlist', retval.data.data.removeProductsFromWishlist.wishlist);
        }
      }

    }
  },
  /**
   * remove product wishlist
   *
   * @param {object} integer id
   */
  async removeProductWishlist({ dispatch }, { id }) {
    const retval = await removeProductFromWishlist(id);
    if (retval) {
      dispatch("loadUser");
    }
  },

  /** wishlist related */

  async addWishItemDescription(_, { wishItemId, description, qty }) {

    const retval = await addWishlistItemDescription({ wishItemId, description, qty });
    return retval;
  },
  async shareWishlist(_, { name, emails, message }) {

    const retval = await handleShareWishlist({ name, emails, message });
    return retval;
  },
  async getSharedWishlist(_, sharing_code) {
    const retval = await getSharedWishlistBySharingCode(sharing_code);
    return retval;
  },
  /**
   * Create user account
   *
   * @param {object} object account
   * @returns
   */
  async createAccountFromOrder({ commit }, account) {
    commit("setFake", null);
    const retval = await createAccountFromOrder(account);

    if (retval != false) {
      commit("setUserToken", retval);
      commit("setIsLoggedIn", true);
      commit("setIsApproved", false);
      const me = await getCurrent();
      if (me != false) {
        let wl = me.wishlist;
        if (wl == null) {
          wl = { id: null, items: [] };
        }
        commit("setWishlist", wl);
        commit("setCurrentUser", me);
        const newCartToken = await getCustomerCartToken();
        const oldCartToken = store.getters["cart/getCartServerToken"];
        if (oldCartToken != null) {
          await mergeCart(oldCartToken, newCartToken);
        }
        store.commit("cart/setServerToken", newCartToken);
        await store.dispatch("cart/loadCart");
      } else {
        commit("setUserToken", null);
        commit("setIsLoggedIn", false);
        commit("setIsApproved", false);
        commit("setCurrentUser", {});
      }
    } else {
      return false;
    }
    return true;
  },
    async loadReviews({ commit }) {
    const myReviews = await getReviews();
    if (myReviews.items.length > 0) {
      commit("setReviews", myReviews.items);
      return myReviews;
    }
  },
};

const mutations = {
  /**
   * set user token
   *
   * @param {string} payload
   */
  setUserToken(state, payload) {
    state.userToken = payload;
    if (payload != null) {
      localStorage.setItem("userServerToken", payload);
    } else {
      localStorage.removeItem("userServerToken");
    }
  },
  /**
   * Set is logged in
   *
   * @param {boolean} payload
   * @private
   */
  setIsLoggedIn(state, payload) {
    state.isLoggedIn = payload;
  },
  /**
 * Set is approved 
 * 
 * @param {boolean} payload
 * @private
 */
  setIsApproved(state, payload) {
    state.isApproved = payload;
  },
  /**
   * set Current user
   *
   * @param {object} payload
   * @private
   */
  setCurrentUser(state, payload) {
    state.current = payload;
  },
  /**
   * set countries
   *
   * @param {array} payload
   * @private
   */
  setCountries(state, payload) {
    state.countries = payload;
  },
  /**
   * set account tab index
   *
   * @param {integer} payload
   */
  setAccountTabIndex(state, payload) {
    state.accountTabIndex = payload;
  },
  /**
   * set Edit address id
   *
   * @param {integer} payload
   */
  setEditAddressId(state, payload) {
    state.editAddressId = payload;
  },
  setEditAddresses(state, payload) {
    state.editAddresses = payload;
  },
  /**
   * set wishlist
   *
   * @param {object} payload
   */
  setWishlist(state, payload) {
    if (payload == null) {
      payload = { id: null, items: [] };
    }
    state.wishlist = payload;
  },
  /**
   * a fake set when you don't need any other object in you dispatch
   *
   * @param {object} state
   * @param {any} payload
   * @private
   *
   */
  setFake(state, payload) {
    state.fake = payload;
  },
  /**
   * set orders
   *
   * @param {array} payload
   */
  setMyOrders(state, payload) {
    state.myOrders = payload;
  },
  setMyOrdersTotalCount(state, payload) {
    state.totalOrdersCount = payload;
  },
  setEditUser(state, payload) {
    state.editUser = payload;
  },
  setEditPassword(state, payload) {
    state.editPassword = payload;
  },
  setEditEmail(state, payload) {
    state.editEmail = payload;
  },
  setReviews(state, payload) {
    state.reviews = payload;
  },
};

const getters = {
  getIsLoggedIn: (state) => state.isLoggedIn,
  getIsApproved: (state) => state.isApproved,
  getCurrentUser: (state) => state.current,
  getUserToken: (state) => state.userToken,
  getUserEmail: (state) => state.userEmail,
  getWishlist: (state) => state.wishlist.items,
  getMyOrders: (state) => state.myOrders,
  getMyOrdersTotalCount: (state) => state.totalOrdersCount,
  getWishlistId: (state) => state.wishlist.id,
  getEditUser: (state) => state.editUser,
  getEditPassword: (state) => state.editPassword,
  getEditAddresses: (state) => state.editAddresses,

  getEditEmail: (state) => state.editEmail,
  getWishlistQuantity: (state) => state.wishlist.items.length,
  getCountries: (state) => {
    const countries = [];
    state.countries.forEach(function (row) {
      const country = { value: row.id, text: row.full_name_locale };
      countries.push(country);
    });
    return countries;
  },
  getRegions: (state) => (id) => {
    const country = state.countries.find((o) => {
      if (o.id == id) {
        return true; // stop searching
      }
    });
    if (country != null) {
      if (country.available_regions != null) {
        const regions = [];
        country.available_regions.forEach((element) => {
          const option = {
            value: element.id,
            text: element.name,
          };
          regions.push(option);
        });
        return regions;
      } else {
        return null;
      }
    }
  },
  getDefaultShippingAddress: (state) => {
    if (typeof state.current.addresses != "undefined") {
      const index = state.current.addresses.findIndex(
        (item) => item.id == state.current.default_shipping
      );
      return state.current.addresses[index];
    } else {
      return null;
    }
  },
  getDefaultBillingAddress: (state) => {
    if (typeof state.current.addresses != "undefined") {
      const index = state.current.addresses.findIndex(
        (item) => item.id == state.current.default_billing
      );
      return state.current.addresses[index];
    } else {
      return null;
    }
  },
  getAddressByID: (state) => (id) => {
    if (typeof state.current.addresses != "undefined") {
      const index = state.current.addresses.findIndex((item) => item.id == id);
      return state.current.addresses[index];
    } else {
      return null;
    }
  },
  isProductInWishlist: (state) => (sku) => {
    if (state.wishlist.items.length > 0) {
      const obj = state.wishlist.items.find((o) => {
        if (o.product.sku == sku) {
          return true;
        }
      });
      if (obj == null) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  },
  getProductInWishlistId: (state) => (sku) => {
    if (state.wishlist.items.length > 0) {
      const obj = state.wishlist.items.find((o) => {
        if (o.product.sku == sku) {
          return true;
        }
      });
      if (obj == null) {
        return null;
      } else {
        return obj.id;
      }
    } else {
      return null;
    }
  },

  getAccountTabIndex: (state) => state.accountTabIndex,
  getEditAddressId: (state) => state.editAddressId,
  getReviews: (state) => state.reviews,
};

// export this module.
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
